import React from "react"
import { Link, graphql } from "gatsby"
import get from 'lodash/get'
import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Share from "../components/share"
import { rhythm, scale } from "../utils/typography"
import 'prismjs/themes/prism.css'

class BlogPostTemplate extends React.Component {
	render() {
		const post = this.props.data.markdownRemark
		const siteTitle = get(this.props, 'data.site.siteMetadata.title')
		const siteUrl = get(this.props, 'data.site.siteMetadata.url')
		const fullArticleUrl = `${siteUrl}${post.frontmatter.path}`
		const { previous, next } = this.props.pageContext
		const tags = post.frontmatter.tags.reduce((acc, tag, idx, array) => {
			const tagUrl = `/tag/${tag}`
			return [
				...acc,
				<span key={tagUrl}>
                    <Link to={tagUrl}>{tag}</Link>{' '}
					{idx + 1 !== array.length ? ', ' : ''}
                </span>,
			]
		}, [])
		return (
			<Layout location={this.props.location} title={siteTitle}>
				<SEO
					title={post.frontmatter.title}
					description={post.frontmatter.description || post.excerpt}
				/>
				<h1>{post.frontmatter.title}</h1>
				<p
					style={{
						...scale(-1 / 5),
					}}
				>
                	tags: {tags}
                </p>
				<p
					style={{
						...scale(-1 / 5),
						display: `block`,
						marginBottom: rhythm(1),
						marginTop: rhythm(-1),
					}}
				>
					{post.frontmatter.date}
				</p>
				<div dangerouslySetInnerHTML={{ __html: post.html }} />
				<hr
					style={{
						marginBottom: rhythm(1),
					}}
				/>
				<Bio />

				<ul
					style={{
						display: `flex`,
						flexWrap: `wrap`,
						justifyContent: `space-between`,
						listStyle: `none`,
						padding: 0,
					}}
				>
					<li>
						{previous && (
							<Link to={previous.frontmatter.path} rel="prev">
								← {previous.frontmatter.title}
							</Link>
						)}
					</li>
					<li>
						{next && (
							<Link to={next.frontmatter.path} rel="next">
								{next.frontmatter.title} →
							</Link>
						)}
					</li>
				</ul>

				<div>
					<p
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}
					>
                        <Share
							path={fullArticleUrl}
							text={post.frontmatter.title}
							twitterHandle={get(
								this.props,
								'data.site.siteMetadata.twitterHandle'
							)}
						/>
					</p>
				</div>
			</Layout>
		)
	}
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostByPath($postPath: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(frontmatter: { path: { eq: $postPath } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        path
        title
        date(locale: "fr-FR", formatString: "DD/MM/YYYY[ (c'était un ]dddd[)]")
        tags
        enVersion
        frVersion
      }
    }
  }
`
